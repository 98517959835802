import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

export const ORGANIC_SHAPES_SERVICE_TILE_POSITION = [
  {
    blob1: {
      top: '-25%',
      left: '-25%',
    },
    blob2: {
      left: '75%',
      top: '75%',
    },
  },
  {
    blob1: {
      top: '-25%',
      left: '75%',
    },
    blob2: {
      left: '75%',
      top: '75%',
    },
  },
  {
    blob1: {
      top: '-25%',
      left: '75%',
    },
    blob2: {
      top: '75%',
      left: '-25%',
    },
  },
  {
    blob1: {
      top: '75%',
      left: '75%',
    },
    blob2: {
      top: '75%',
      left: '-25%',
    },
  },
  {
    blob1: {
      top: '75%',
      left: '-25%',
    },
    blob2: {
      top: '-25%',
      left: '-25%',
    },
  },
  {
    blob1: {
      top: '75%',
      left: '-25%',
    },
    blob2: {
      top: '-25%',
      left: '75%',
    },
  },
];

const OrganicShape = styled('svg')(() => ({
  zIndex: -1,
  position: 'absolute',
  height: 200,
  width: 200,
  overflow: 'hidden',
}));

const Blob1 = styled(OrganicShape)(({ index }) => ({
  top: ORGANIC_SHAPES_SERVICE_TILE_POSITION[index % 6].blob1.top,
  left: ORGANIC_SHAPES_SERVICE_TILE_POSITION[index % 6].blob1.left,
}));

const Blob2 = styled(OrganicShape)(({ index }) => ({
  top: ORGANIC_SHAPES_SERVICE_TILE_POSITION[index % 6].blob2.top,
  left: ORGANIC_SHAPES_SERVICE_TILE_POSITION[index % 6].blob2.left,
}));

const BackgroundTileWithOrganicShapes = ({ index, children }) => (
  <>
    <Blob1 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 180" index={index}>
      <path
        fill="#E8F0AA"
        d="M69.2,-39.3C82.5,-17,80.9,14.4,67,35.2C53,55.9,26.5,65.9,1.9,64.8C-22.7,63.7,-45.4,51.5,-59.7,30.6C-74,9.7,-79.9,-19.9,-68.6,-41.1C-57.2,-62.3,-28.6,-75.1,-0.3,-74.9C28,-74.8,56,-61.6,69.2,-39.3Z"
        transform="translate(100 100)"
      />
    </Blob1>
    <Blob2 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 180" index={index}>
      <path
        fill="#E3F7F2"
        d="M69.2,-39.3C82.5,-17,80.9,14.4,67,35.2C53,55.9,26.5,65.9,1.9,64.8C-22.7,63.7,-45.4,51.5,-59.7,30.6C-74,9.7,-79.9,-19.9,-68.6,-41.1C-57.2,-62.3,-28.6,-75.1,-0.3,-74.9C28,-74.8,56,-61.6,69.2,-39.3Z"
        transform="translate(100 100)"
      />
    </Blob2>
    {children}
  </>
);

BackgroundTileWithOrganicShapes.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default BackgroundTileWithOrganicShapes;
