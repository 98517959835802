import React from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import ArticleCard from 'components/ArticleCard';
import {
  homepageSeeAllArticlesClick,
  homepageArticleTileClick,
} from 'state/modules/analytics/homepage';
import SectionHeader from 'views/Homepage/SectionHeader';

const ArticleSection = (props) => {
  const { articles } = props;
  const dispatch = useDispatch();

  const dispatchSeeAllArticlesClick = () => {
    dispatch(homepageSeeAllArticlesClick);
  };

  const dispatchArticleTileClick = (articleName, articleSlug) => {
    dispatch(homepageArticleTileClick({ articleName, articleSlug }));
  };

  return (
    <Box
      sx={(theme) => ({
        marginBottom: '40px',
        [theme.breakpoints.up('md')]: {
          marginBottom: '68px',
        },
      })}
    >
      <SectionHeader
        id="inspiration"
        header="Get Ready For Your Next Event"
        subheader="No matter what you’re celebrating, we have party themes and planning tips to help along the way."
        linkText="See all articles"
        linkPath="/articles"
        onLinkClick={dispatchSeeAllArticlesClick}
      >
        <Grid
          container
          spacing={3}
          sx={{
            padding: '0 0 0 0',
          }}
        >
          {articles.map((article) => (
            <Grid item xs={12} sm={6} md={6} lg={3} key={article.slug} justifyContent="center">
              <ArticleCard onClick={dispatchArticleTileClick} {...article} topicTagName="" />
            </Grid>
          ))}
        </Grid>
      </SectionHeader>
    </Box>
  );
};

ArticleSection.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      metaTitle: PropTypes.string.isRequired,
      hed: PropTypes.string.isRequired,
      thumbSquareUrl: PropTypes.string.isRequired,
      slug: PropTypes.string.IsRequired,
    })
  ).isRequired,
};

export default ArticleSection;
