import React from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Typography, JumpToLocation } from '@tkww/the-bash-frontend';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import { SectionContainer } from 'components/grid';
import { homepageJumpToCtaClick } from 'state/modules/analytics/homepage';
import jump from 'helpers/jump';

const StyledSectionContainer = styled(SectionContainer)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  columnGap: '0',
  padding: '0',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    columnGap: '150px',
    padding: '0 100px',
  },
}));

const StyledJumpSection = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledJumpSectionTop = styled(Box)({
  margin: '40px 0px 8px 0px',
  display: 'flex',
  alignItems: 'center',
});

const StyledJumpToLocationContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
    marginTop: '12px',
  },
}));

const StyledMobileJumpToLocation = styled(JumpToLocation)(({ theme }) => ({
  display: 'flex',
  marginLeft: 12,
  [theme.breakpoints.up('md')]: { display: 'none' },
}));

const jumpToItems = [
  {
    heading: 'Local Pros',
    description: 'Book the perfect vendor or entertainer for your event and budget.',
    jumpToTarget: '#service-tiles',
    clickTarget: 'Services section jump to',
  },
  {
    heading: 'Event Ideas',
    description:
      'Find inspiration for milestone events, seasonal holidays, and everything in between.',
    jumpToTarget: '#event-ideas',
    clickTarget: 'Events section jump to',
  },
  {
    heading: 'Inspiration & More',
    description: 'From themes to entertainment, planning your celebration has never been easier.',
    jumpToTarget: '#inspiration',
    clickTarget: 'Inspiration section jump to',
  },
];

const JumpToSection = ({ heading, description, jumpToTarget, clickTarget }) => {
  const dispatch = useDispatch();

  const handleJumpTo = () => {
    dispatch(homepageJumpToCtaClick(clickTarget));
  };

  const scrollToTarget = () => {
    jump(jumpToTarget);
    handleJumpTo();
  };

  return (
    <StyledJumpSection>
      <StyledJumpSectionTop>
        <Typography
          variant="h4"
          component="h3"
          align="center"
          sx={{ margin: 0, cursor: 'pointer' }}
          onClick={scrollToTarget}
        >
          {heading}
        </Typography>
        <StyledMobileJumpToLocation
          target={jumpToTarget}
          defaultIconSize="12px"
          onClick={handleJumpTo}
        />
      </StyledJumpSectionTop>
      <Typography
        variant="body2"
        align="center"
        onClick={scrollToTarget}
        sx={{ cursor: 'pointer' }}
      >
        {description}
      </Typography>
      <StyledJumpToLocationContainer>
        <JumpToLocation target={jumpToTarget} defaultIconSize="12px" onClick={handleJumpTo} />
      </StyledJumpToLocationContainer>
    </StyledJumpSection>
  );
};

const JumpToSections = () => (
  <StyledSectionContainer backgroundColor="transparent">
    {jumpToItems.map((item) => (
      <JumpToSection key={item.heading} {...item} />
    ))}
  </StyledSectionContainer>
);

export default JumpToSections;

JumpToSection.propTypes = {
  heading: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  jumpToTarget: propTypes.string.isRequired,
  clickTarget: propTypes.string.isRequired,
};
