import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Typography, Link } from '@tkww/the-bash-frontend';
import getResizeImageUrl from 'helpers/media/getResizeImageUrl';
import { highLevelServiceTileClick, serviceTileLinkClick } from 'state/modules/analytics/homepage';
import BackgroundTileWithOrganicShapes from 'components/ServiceTile/BackgroundTileWithOrganicShapes';

const IMAGE_TILE = 0;
const MID_LEVEL_SERVICES_TILE = 1;
const INDIVIDUAL_SERVICES_TILE = 2;

const getTileTransform = (currentTile) => {
  if (currentTile === MID_LEVEL_SERVICES_TILE) {
    return 'rotateY(180deg)';
  }
  if (currentTile === INDIVIDUAL_SERVICES_TILE) {
    return 'rotateY(360deg)';
  }
  return 'rotateY(0deg)';
};

const TileContainer = styled(Box)(({ theme, currentTile }) => ({
  height: 383,
  border: currentTile !== IMAGE_TILE ? `1px solid ${theme.palette.grey[400]}` : 'none',
  textAlign: 'center',
  position: 'relative',
  'transform-style': 'preserve-3d',
  transform: getTileTransform(currentTile),
  'transition-duration': '600ms',
  maxWidth: 362,
  margin: '0 auto',
}));

const Tile = styled(Box)({
  'backface-visibility': 'hidden',
  position: 'absolute',
  width: '100%',
  height: '100%',
});

const ImageTile = styled(Tile)(({ imageUrl, showTile }) => ({
  background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.98) 80.73%), url(${getResizeImageUrl(
    imageUrl,
    372,
    383
  )}) center no-repeat`,
  display: showTile ? 'block' : 'none',
  cursor: 'pointer',
}));

const ImageTileTextSection = styled(Box)({
  color: '#FFFFFF',
  paddingTop: 209,
});

const ImageTileHeading = styled(Typography)({
  lineHeight: '22px',
  marginBottom: 8,
});

const ImageTileSubHeading = styled(Typography)({
  maxWidth: 268,
  margin: '0 auto',
});

const MidLevelServicesTile = styled(Tile)({
  background: 'white',
  transform: 'rotateY(180deg)',
  overflow: 'hidden',
});

const IndividualServicesTile = styled(Tile)(({ showTile }) => ({
  background: 'white',
  display: showTile ? 'block' : 'none',
  zIndex: -1,
  overflow: 'hidden',
}));

const BackTileHeading = styled(Typography)({
  lineHeight: '22px',
  padding: '19px 0 16px',
  margin: 0,
});

const ServiceLinkText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: 8,
  fontFamily: theme.fonts.regular,
}));

const ViewAllServicesLink = styled(ServiceLinkText)({
  fontWeight: 800,
});

const MidLevelServiceButton = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.darker,
    textDecoration: 'underline',
  },
}));

const IndividualServiceLink = styled(Link)(({ theme }) => ({
  fontFamily: theme.fonts.regular,
}));

const BackArrow = styled(ArrowCircleLeftOutlinedIcon)(({ theme }) => ({
  top: 21,
  left: 21,
  width: 32,
  height: 32,
  position: 'absolute',
  cursor: 'pointer',
  color: theme.palette.primary.main,
  borderRadius: '50%',
  '&:hover,&:focus,&:active': {
    background: 'rgba(184, 208, 255, 0.5)',
  },
}));

const ExitButton = styled(HighlightOffOutlinedIcon)(({ theme }) => ({
  bottom: 16,
  right: 0,
  left: 0,
  margin: '0 auto',
  width: 32,
  height: 32,
  position: 'absolute',
  cursor: 'pointer',
  color: theme.palette.primary.main,
  borderRadius: '50%',
  zIndex: 1,
  '&:hover,&:focus,&:active': {
    background: 'rgba(184, 208, 255, 0.5)',
  },
}));

const forwardArrowStyles = (currentTile, imageTile) => {
  const enableForwardArrowClick = currentTile === imageTile;
  return {
    bottom: 16,
    right: 0,
    left: 0,
    margin: '0 auto',
    width: 32,
    height: 32,
    position: 'absolute',
    color: 'white',
    borderRadius: '50%',
    pointerEvents: enableForwardArrowClick ? 'auto' : 'none',
    '&:hover,&:focus,&:active': {
      background: 'rgba(255,255,255, 0.3)',
    },
  };
};

export const ServiceTile = ({ serviceData, index }) => {
  const {
    pluralForm,
    description,
    midLevelServices,
    individualServices,
    imageUrl,
    viewAllLink,
    hasMidLevelServices,
    noFollowViewAllLink,
  } = serviceData;

  const [currentTile, setCurrentTile] = useState(IMAGE_TILE);

  const prevTile = useRef(IMAGE_TILE);

  useEffect(() => {
    prevTile.current = currentTile;
  }, [currentTile]);

  const dispatch = useDispatch();

  const [selectedMidLevelServiceName, setSelectedMidLevelServiceName] = useState(null);

  const onTopLevelServiceTileClick = () => {
    dispatch(highLevelServiceTileClick(pluralForm));
    setCurrentTile(MID_LEVEL_SERVICES_TILE);
  };

  const flipToIndividualServices = (midLevelServiceName) => {
    setSelectedMidLevelServiceName(midLevelServiceName);
    setCurrentTile(INDIVIDUAL_SERVICES_TILE);
  };

  const onServiceLinkClick = (serviceName, isMidLevelService = false) => {
    dispatch(serviceTileLinkClick(serviceName));
    if (isMidLevelService) {
      flipToIndividualServices(serviceName);
    }
  };

  const showImageTile = () => currentTile === IMAGE_TILE || prevTile.current === IMAGE_TILE;

  const showIndividualServicesTile = (midLevelService) =>
    selectedMidLevelServiceName &&
    selectedMidLevelServiceName === midLevelService.name &&
    (currentTile === INDIVIDUAL_SERVICES_TILE ||
      (prevTile.current === INDIVIDUAL_SERVICES_TILE && currentTile === MID_LEVEL_SERVICES_TILE));

  return (
    <TileContainer currentTile={currentTile}>
      <ImageTile
        showTile={showImageTile()}
        imageUrl={imageUrl}
        role="button"
        tabIndex={currentTile === IMAGE_TILE ? 0 : -1}
        onClick={onTopLevelServiceTileClick}
        onKeyPress={onTopLevelServiceTileClick}
        aria-label={`${pluralForm} Service Tile`}
      >
        <ImageTileTextSection>
          <ImageTileHeading variant="h3">{pluralForm}</ImageTileHeading>
          <ImageTileSubHeading variant="body2">{description}</ImageTileSubHeading>
        </ImageTileTextSection>
        <ArrowCircleRightOutlinedIcon sx={forwardArrowStyles(currentTile, IMAGE_TILE)} />
      </ImageTile>
      <MidLevelServicesTile currentTile={currentTile} imageUrl={imageUrl}>
        <BackgroundTileWithOrganicShapes index={index}>
          <BackTileHeading variant="h4">{pluralForm}</BackTileHeading>
          {hasMidLevelServices
            ? midLevelServices.map((midLevelService) => (
                <ServiceLinkText variant="body1" key={midLevelService.name}>
                  <MidLevelServiceButton
                    component="span"
                    onClick={() => onServiceLinkClick(midLevelService.name, true)}
                    onKeyPress={() => onServiceLinkClick(midLevelService.name, true)}
                    role="button"
                    tabIndex={currentTile === MID_LEVEL_SERVICES_TILE ? 0 : -1}
                  >
                    {midLevelService.name}
                  </MidLevelServiceButton>
                </ServiceLinkText>
              ))
            : individualServices.map((service) => (
                <ServiceLinkText variant="body1" key={service.fileName}>
                  <IndividualServiceLink
                    path={`/services/${service.fileName}`}
                    onClick={() => onServiceLinkClick(service.pluralForm)}
                    onKeyPress={() => onServiceLinkClick(service.pluralForm)}
                    tabIndex={currentTile === MID_LEVEL_SERVICES_TILE ? 0 : -1}
                  >
                    {service.pluralForm}
                  </IndividualServiceLink>
                </ServiceLinkText>
              ))}
          {viewAllLink && (
            <ViewAllServicesLink variant="body1">
              <Link
                path={`/${viewAllLink}`}
                rel={noFollowViewAllLink ? 'nofollow' : ''}
                tabIndex={currentTile === MID_LEVEL_SERVICES_TILE ? 0 : -1}
              >
                {`View All ${pluralForm}`}
              </Link>
            </ViewAllServicesLink>
          )}
          <ExitButton
            aria-label={`Exit to ${pluralForm} Service Tile`}
            role="button"
            tabIndex={currentTile === MID_LEVEL_SERVICES_TILE ? 0 : -1}
            onClick={() => setCurrentTile(IMAGE_TILE)}
            onKeyPress={() => setCurrentTile(IMAGE_TILE)}
          />
        </BackgroundTileWithOrganicShapes>
      </MidLevelServicesTile>
      {midLevelServices &&
        midLevelServices.map((midLevelService) => (
          <IndividualServicesTile
            showTile={showIndividualServicesTile(midLevelService)}
            key={`${midLevelService.name} individual`}
          >
            <BackgroundTileWithOrganicShapes index={index}>
              <BackArrow
                role="button"
                aria-label={`Back to ${midLevelService.name} Service Tile`}
                tabIndex={currentTile === INDIVIDUAL_SERVICES_TILE ? 0 : -1}
                onClick={() => setCurrentTile(MID_LEVEL_SERVICES_TILE)}
                onKeyPress={() => setCurrentTile(MID_LEVEL_SERVICES_TILE)}
              />
              <BackTileHeading variant="h4">{midLevelService.name}</BackTileHeading>
              {midLevelService.individualServices.map((service) => (
                <ServiceLinkText variant="body1" key={service.fileName}>
                  <IndividualServiceLink
                    path={`/services/${service.fileName}`}
                    onClick={() => onServiceLinkClick(service.personForm)}
                    onKeyPress={() => onServiceLinkClick(service.personForm)}
                    data-testid={`individual-service-link-${service.fileName}`}
                  >
                    {service.personForm}
                  </IndividualServiceLink>
                </ServiceLinkText>
              ))}
              <ViewAllServicesLink variant="body1">
                <IndividualServiceLink
                  path={`/${midLevelService.viewAllLink}`}
                  rel={midLevelService.noFollowViewAllLink ? 'nofollow' : ''}
                >
                  {midLevelService.viewAllCopy || `View All ${midLevelService.name}`}
                </IndividualServiceLink>
              </ViewAllServicesLink>
              <ExitButton
                aria-label={`Exit to ${pluralForm} Service Tile`}
                role="button"
                tabIndex={currentTile === INDIVIDUAL_SERVICES_TILE ? 0 : -1}
                onClick={() => setCurrentTile(IMAGE_TILE)}
                onKeyPress={() => setCurrentTile(IMAGE_TILE)}
              />
            </BackgroundTileWithOrganicShapes>
          </IndividualServicesTile>
        ))}
    </TileContainer>
  );
};

ServiceTile.propTypes = {
  index: PropTypes.number.isRequired,
  serviceData: PropTypes.shape({
    pluralForm: PropTypes.string.isRequired,
    hasMidLevelServices: PropTypes.bool.isRequired,
    noFollowViewAllLink: PropTypes.bool,
    description: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    viewAllLink: PropTypes.string,
    midLevelServices: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        viewAllLink: PropTypes.string.isRequired,
        noFollowViewAllLink: PropTypes.bool,
        individualServices: PropTypes.arrayOf(
          PropTypes.shape({
            personForm: PropTypes.string.isRequired,
            fileName: PropTypes.string.isRequired,
          })
        ),
      })
    ),
    individualServices: PropTypes.arrayOf(
      PropTypes.shape({
        personForm: PropTypes.string,
        fileName: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default ServiceTile;
