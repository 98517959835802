import React from 'react';
import { Typography } from '@tkww/the-bash-frontend';
import { styled } from '@mui/material/styles';
import { useTheme, Box } from '@mui/material';

import { SectionContainer } from 'components/grid';
import { DESKTOP_HERO_IMAGE_URL, MOBILE_HERO_IMAGE_URL } from 'constants/Homepage';

const StyledSectionContainer = styled(SectionContainer)(({ theme }) => ({
  color: theme.palette.dark.text,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    alignItems: 'normal',
    justifyContent: 'space-between',
  },
}));

const StyledHeroText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  paddingTop: '0px',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    width: 422,
    textAlign: 'left',
    paddingTop: '50px',
  },
}));

const StyledHeader = styled(Typography)(({ theme }) => ({
  padding: '0 20px',
  fontSize: '40px',
  lineHeight: '48px',
  [theme.breakpoints.up('md')]: {
    width: 285,
    padding: '0',
    fontSize: '44px',
    lineHeight: '55px',
  },
  [theme.breakpoints.up('lg')]: {
    width: 422,
    padding: '0',
    fontSize: '44px',
    lineHeight: '55px',
  },
}));

const StyledImg = styled('img')(({ theme }) => ({
  width: '100vw',
  minHeight: 265,
  [theme.breakpoints.up('md')]: {
    width: '100%',
    minHeight: 327,
    objectFit: 'cover',
    objectPosition: 'left',
  },
}));

const HeadingWithImage = () => {
  const theme = useTheme();
  const desktopImageMediaQuery = `(min-width: ${theme.breakpoints.values.sm}px)`;

  return (
    <StyledSectionContainer backgroundColor="transparent">
      <Box
        component="picture"
        sx={{
          position: { md: 'absolute' },
          right: { md: -50 },
          width: { sm: '100vw', md: 'calc(100% - 200px)', lg: 'calc(100% - 324px)' },
        }}
      >
        <source media={desktopImageMediaQuery} srcSet={DESKTOP_HERO_IMAGE_URL} />
        <StyledImg src={MOBILE_HERO_IMAGE_URL} alt="people at a party" />
      </Box>
      <StyledHeroText>
        <StyledHeader variant="h1">Bring your celebration to life!</StyledHeader>
        <Typography variant="subhead2" component="h2">
          Book local vendors & entertainers, and find inspiration for every occasion—big or small.
        </Typography>
      </StyledHeroText>
    </StyledSectionContainer>
  );
};

export default HeadingWithImage;
