import React from 'react';
import PropTypes from 'prop-types';
import MetaDataMarkup from 'components/MetaDataMarkup';
import { gmConfig } from 'helpers/config';
import { LOGO_IMAGE } from 'constants/Logos';
import HomepageView from 'views/Homepage';
import { getInitialPropsForHomepage } from 'helpers/homepage/index.js';
import { DESKTOP_HERO_IMAGE_URL, MOBILE_HERO_IMAGE_URL } from 'constants/Homepage';
import configureStore from 'state/configureStore';
import getServerSidePropsWrapper from 'helpers/getServerSidePropsWrapper';

const SITE_NAME = 'The Bash';

export const Homepage = ({ userAgent, homepageContent, featureFlags }) => {
  const { title, description, articles, events } = homepageContent;
  const { hasCreateAccount } = featureFlags;

  return (
    <>
      <MetaDataMarkup
        title={title}
        description={description}
        openGraphProps={{
          title,
          description,
          type: 'website',
          url: `https://${gmConfig.domains.www}`,
          image: LOGO_IMAGE,
          site_name: SITE_NAME,
        }}
        desktopHeroImageUrl={DESKTOP_HERO_IMAGE_URL}
        mobileHeroImageUrl={MOBILE_HERO_IMAGE_URL}
      />
      <HomepageView
        articles={articles}
        events={events}
        userAgent={userAgent}
        {...homepageContent}
        hasCreateAccount={hasCreateAccount}
      />
    </>
  );
};

export const getServerSideProps = configureStore.getServerSideProps(
  (store) => async (ctx) =>
    await getServerSidePropsWrapper({ ...ctx, store }, async (ctx) => {
      return await getInitialPropsForHomepage(ctx);
    })
);

Homepage.propTypes = {
  userAgent: PropTypes.string.isRequired,
  homepageContent: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    articles: PropTypes.array,
    events: PropTypes.array,
  }).isRequired,
  featureFlags: PropTypes.shape({}),
};

export default Homepage;
