import React from 'react';
import { Grid } from '@mui/material';
import { Link, Typography } from '@tkww/the-bash-frontend';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import Image from 'components/Image';

const EVENTS_PATH = 'events/';

const StyledLink = styled(Link)(({ theme }) => ({
  backgroundColor: theme.palette.white,
  boxShadow: '0 0 4.4px 0.7px rgba(0, 0, 0, 0.04)',
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: 6,
  display: 'block',
  color: theme.palette.text.primary,
  overflow: 'hidden',
  position: 'relative',
  '&:hover': {
    color: theme.palette.text.primary,
    boxShadow: theme.constants.boxShadows.dark,
    textDecoration: 'none',
    borderColor: theme.palette.primary,
  },
}));

const StyledImage = styled(Image)({
  width: '100%',
  height: '100%',
  aspectRatio: 1,
});

const StyledTypography = styled(Typography)({
  color: 'white',
  fontWeight: 'bold',
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 0,
  padding: '0 8px',
  backgroundColor: 'rgba(28, 28, 28, .5)',
  fontSize: 20,
});

export const EventTile = ({
  homepageTileCopy,
  eventFileName,
  homepageTileImage,
  eventName,
  onClick,
}) => {
  const eventPath = EVENTS_PATH + eventFileName;

  return (
    <Grid item xs={6} md={4} lg={2}>
      <StyledLink path={eventPath} onClick={() => onClick(eventName, eventPath)}>
        <StyledImage src={homepageTileImage} alt={eventFileName} />
        <StyledTypography variant="h3" component="p">
          {homepageTileCopy}
        </StyledTypography>
      </StyledLink>
    </Grid>
  );
};

EventTile.propTypes = {
  homepageTileCopy: PropTypes.string.isRequired,
  homepageTileImage: PropTypes.string.isRequired,
  eventFileName: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EventTile;
