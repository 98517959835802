import React from 'react';
import { Button, Typography } from '@tkww/the-bash-frontend';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

import { homepageSignupLearnMoreClick } from 'state/modules/analytics/homepage';

const StyledSectionContainer = styled(Box)(({ theme }) => ({
  backgroundImage: "url('images-next/ready-to-book/footer-background.svg')",
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top center',
  height: 252,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  padding: '0px 16px 0 16px',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-end',
    padding: '0px 16px 36px 16px',
  },
}));

const StyledLearnMoreButton = styled(Button)({
  textTransform: 'capitalize',
});

export const SignupSection = () => {
  const dispatch = useDispatch();

  const handleLearnMoreClick = () => {
    dispatch(homepageSignupLearnMoreClick);
  };

  return (
    <StyledSectionContainer>
      <Typography variant="h2" sx={{ margin: '0 0 8px 0' }}>
        Ready to book more events as a vendor?
      </Typography>
      <Typography variant="body1" sx={{ margin: '0 0 24px 0' }}>
        Get started here on The Bash.
      </Typography>
      <StyledLearnMoreButton type="secondary" path="/signup/landing" onClick={handleLearnMoreClick}>
        Learn More
      </StyledLearnMoreButton>
    </StyledSectionContainer>
  );
};
SignupSection.propTypes = {};

SignupSection.defaultProps = {};

export default SignupSection;
