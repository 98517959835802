import { CaretIcon, Link, Typography } from '@tkww/the-bash-frontend';
import React from 'react';
import { SectionContainer } from 'components/grid';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const StyledLink = styled(Link)(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: theme.fontSizes['14'],
}));

const StyledSectionContainer = styled(SectionContainer)({
  textAlign: 'center',
});

const StyledCaretIcon = styled(CaretIcon)({
  marginBottom: 6,
});

const SectionHeader = ({ header, subheader, linkText, linkPath, onLinkClick, children, id }) => (
  <StyledSectionContainer backgroundColor="transparent" id={id}>
    <Box sx={{ marginBottom: '32px' }}>
      <Typography variant="h2" marginTop="0px" marginBottom="8px">
        {header}
      </Typography>
      <Typography variant="body1" marginBottom="16px">
        {subheader}
      </Typography>
      <StyledLink path={linkPath} onClick={onLinkClick}>
        {linkText}
        <StyledCaretIcon direction="right" />
      </StyledLink>
    </Box>
    {children}
  </StyledSectionContainer>
);

SectionHeader.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  linkPath: PropTypes.string.isRequired,
  onLinkClick: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  id: PropTypes.string,
};

SectionHeader.defaultProps = {
  id: '',
};

export default SectionHeader;
