import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box, Grid } from '@mui/material';

import {
  homepageEventTileClick,
  homepageSeeAllEventsClick,
} from 'state/modules/analytics/homepage';
import EventTile from 'components/EventTile';

import SectionHeader from 'views/Homepage/SectionHeader/index';

const EventSection = (props) => {
  const { events } = props;
  const dispatch = useDispatch();

  const dispatchEventTileClick = (eventType, eventPath) => {
    dispatch(homepageEventTileClick({ eventType, eventPath }));
  };

  const dispatchSeeAllEventsClick = () => {
    dispatch(homepageSeeAllEventsClick);
  };

  return (
    <Box
      sx={{
        margin: { xs: '72px auto', md: '100px auto' },
      }}
    >
      <SectionHeader
        id="event-ideas"
        header="Find Inspiration For Any Celebration"
        subheader="Impress your guests with unique entertainment and party ideas for every occasion."
        linkPath="/events"
        linkText="See All Events"
        onLinkClick={dispatchSeeAllEventsClick}
      >
        <Grid container spacing={3}>
          {events.map((event) => (
            <EventTile key={event.eventFileName} onClick={dispatchEventTileClick} {...event} />
          ))}
        </Grid>
      </SectionHeader>
    </Box>
  );
};

EventSection.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      homepageTileCopy: PropTypes.string.isRequired,
      homepageTileImage: PropTypes.string.isRequired,
      eventFileName: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default EventSection;
