export const MUSICAL_ENTERTAINMENT = {
  pluralForm: 'Musical Entertainment',
  viewAllLink: 'musical-entertainment',
  hasMidLevelServices: true,
  imageUrl: 'https://media-api.xogrp.com/images/11be657b-c740-4e24-83c3-a6a86b9af33f',
  description: 'Bands, Soloists, DJs, Ensembles, Cultural Bands...',
  midLevelServices: [
    {
      name: 'DJs',
      individualServices: [
        { personForm: 'Latin DJ', fileName: 'latin-dj' },
        { personForm: 'Wedding DJ', fileName: 'wedding-dj' },
      ],
      viewAllLink: 'dj',
    },
    {
      name: 'Ensembles',
      individualServices: [
        { personForm: 'A Cappella Group', fileName: 'a-cappella' },
        { personForm: 'Barbershop Quartet', fileName: 'barbershop-quartet' },
        { personForm: 'Classical Trio', fileName: 'classical-trio' },
        { personForm: 'Choir', fileName: 'gospel-choir' },
        { personForm: 'String Quartet', fileName: 'string-quartet' },
      ],
      viewAllLink: 'ensemble',
    },
    {
      name: 'Live Bands',
      individualServices: [
        { personForm: 'Blues Band', fileName: 'blues-band' },
        { personForm: 'Cover Band', fileName: 'cover-band' },
        { personForm: 'Jazz Band', fileName: 'jazz-band' },
        { personForm: 'Rock Band', fileName: 'rock-band' },
        { personForm: 'Variety Band', fileName: 'variety-band' },
        { personForm: 'Wedding Band', fileName: 'wedding-band' },
      ],
      viewAllLink: 'liveband',
    },
    {
      name: 'Singers',
      individualServices: [
        { personForm: 'Country Singer', fileName: 'country-singer' },
        { personForm: 'Jazz Singer', fileName: 'jazz-singer' },
        { personForm: 'Pop Singer', fileName: 'pop-singer' },
        { personForm: 'Singer Guitarist', fileName: 'singer-guitarist' },
        { personForm: 'Wedding Singer', fileName: 'wedding-singer' },
      ],
      viewAllLink: 'soloists',
      noFollowViewAllLink: true,
    },
    {
      name: 'Soloists',
      individualServices: [
        { personForm: 'Acoustic Guitar', fileName: 'acoustic-guitar' },
        { personForm: 'Bagpiper', fileName: 'bagpipes' },
        { personForm: 'Harpists', fileName: 'harp' },
        { personForm: 'One Man Band', fileName: 'one-man-band' },
        { personForm: 'Pianist', fileName: 'piano' },
        { personForm: 'Saxophonist', fileName: 'saxophone' },
        { personForm: 'Violinist', fileName: 'violin' },
      ],
      viewAllLink: 'soloists',
    },
    {
      name: 'World Bands',
      individualServices: [
        { personForm: 'Celtic Band', fileName: 'celtic-band' },
        { personForm: 'Irish Band', fileName: 'irish-band' },
        { personForm: 'Latin Band', fileName: 'latin-band' },
        { personForm: 'Mariachi Band', fileName: 'mariachi-band' },
        { personForm: 'Polka Band', fileName: 'polka-band' },
        { personForm: 'Steel Drum Band', fileName: 'steel-drum-band' },
      ],
      viewAllLink: 'services/world-music',
      viewAllCopy: 'View All World Music',
    },
  ],
};

export const PHOTOGRAPHERS = {
  pluralForm: 'Photographers',
  viewAllLink: 'event-services',
  noFollowViewAllLink: true,
  hasMidLevelServices: false,
  imageUrl: 'https://media-api.xogrp.com/images/1e724a67-25a0-491a-9302-31469ec5f84d',
  description: 'Wedding Photographers, Videographers...',
  individualServices: [
    { pluralForm: 'Photographers', fileName: 'photographer' },
    { pluralForm: 'Videographers', fileName: 'videographer' },
    { pluralForm: 'Wedding Photographers', fileName: 'wedding-photographer' },
  ],
};

export const VARIETY_ACTS = {
  pluralForm: 'Variety Acts',
  viewAllLink: 'variety-act-landing',
  hasMidLevelServices: true,
  imageUrl: 'https://media-api.xogrp.com/images/4afa3aa2-8314-4d3a-a2d8-59d2a7526cc8',
  description: 'Event Artists, Magicians, Comedians, Impersonators...',
  midLevelServices: [
    {
      name: 'Comedy Acts',
      individualServices: [
        { personForm: 'Clean Comedian', fileName: 'clean-comedian' },
        { personForm: 'Comedian', fileName: 'comedian' },
        { personForm: 'Comedy Hypnotist', fileName: 'comedy-hypnotist' },
        { personForm: 'Emcee', fileName: 'emcee' },
        { personForm: 'Stand Up Comedian', fileName: 'stand-up-comedian' },
      ],
      viewAllLink: 'comedy-acts',
    },
    {
      name: 'Dancers',
      individualServices: [
        { personForm: 'Belly Dancer', fileName: 'belly-dancer' },
        { personForm: 'Cabaret Dancer', fileName: 'cabaret-dancer' },
        { personForm: 'Fire Dancer', fileName: 'fire-dancer' },
        { personForm: 'Flamenco Dancer', fileName: 'flamenco-dancer' },
        { personForm: 'Hawaiian Dancer', fileName: 'hawaiian-dancer' },
        { personForm: 'Hula Dancer', fileName: 'hula-dancer' },
      ],
      viewAllLink: 'dancers',
    },
    {
      name: 'Event Artists',
      individualServices: [
        { personForm: 'Body Painting', fileName: 'body-painting' },
        { personForm: 'Caricaturist', fileName: 'caricaturist' },
        { personForm: 'Face Painting', fileName: 'face-painting' },
        { personForm: 'Henna Artist', fileName: 'henna-artist' },
        { personForm: 'Silhouette Artist', fileName: 'silhouette-artist' },
      ],
      viewAllLink: 'event-artists',
    },
    {
      name: 'Impersonators',
      individualServices: [
        { personForm: 'Cher Impersonator', fileName: 'cher-impersonator' },
        { personForm: 'Donald Trump Impersonator', fileName: 'donald-trump-impersonator' },
        { personForm: 'Elvis Impersonator', fileName: 'elvis-impersonator' },
        { personForm: 'Impersonator', fileName: 'impersonator' },
        { personForm: 'Marilyn Monroe Impersonator', fileName: 'marilyn-monroe-impersonator' },
      ],
      viewAllLink: 'impersonator',
    },
    {
      name: "Kids' Entertainment",
      individualServices: [
        { personForm: 'Balloon Twister', fileName: 'balloon-twister' },
        { personForm: 'Clown', fileName: 'clown' },
        { personForm: 'Costumed Character', fileName: 'costumed-character' },
        { personForm: 'Face Painting', fileName: 'face-painting' },
        { personForm: 'Puppet Shows', fileName: 'puppet-shows' },
        { personForm: 'Singing Telegram', fileName: 'singing-telegram' },
      ],
      viewAllLink: 'kids-party-entertainment',
      viewAllCopy: 'View All Kids Party Entertainment',
    },
    {
      name: 'Magic & Illusions',
      individualServices: [
        { personForm: 'Comedy Magician', fileName: 'comedy-magician' },
        { personForm: 'Fortune Teller', fileName: 'fortune-teller' },
        { personForm: 'Hypnotist', fileName: 'hypnotist' },
        { personForm: 'Illusionist', fileName: 'illusionist' },
        { personForm: 'Magician', fileName: 'magician' },
        { personForm: 'Psychic', fileName: 'psychic' },
      ],
      viewAllLink: 'magic-illusion',
      viewAllCopy: 'View All Magic & Illusion',
    },
  ],
};

export const PARTY_RENTALS = {
  pluralForm: 'Party Rentals',
  hasMidLevelServices: true,
  imageUrl: 'https://media-api.xogrp.com/images/407d424e-bbaf-41a3-a2b7-bd63d1b5b0a7',
  description: 'Tents, Bounce Houses, Photo Booths, Animals for Parties...',
  midLevelServices: [
    {
      name: 'Animal Attractions',
      individualServices: [
        { personForm: 'Animals For Parties', fileName: 'animals-for-parties' },
        { personForm: 'Petting Zoo', fileName: 'petting-zoo' },
      ],
      viewAllLink: 'party-rental',
      noFollowViewAllLink: true,
    },
    {
      name: 'Party Rentals',
      individualServices: [
        { personForm: 'Dunk Tank Rentals', fileName: 'dunk-tank' },
        { personForm: 'Party Inflatables', fileName: 'party-inflatables' },
        { personForm: 'Party Tent Rentals', fileName: 'party-tent-rentals' },
        { personForm: 'Photo Booth Rental', fileName: 'photo-booths' },
      ],
      viewAllLink: 'party-rental',
    },
  ],
};

export const SPEAKERS_AND_OFFICIANTS = {
  pluralForm: 'Speakers & Officiants',
  hasMidLevelServices: true,
  imageUrl: 'https://media-api.xogrp.com/images/d78761b6-5fc2-4f1b-8694-662e27ccd23c',
  description: 'Motivational Speakers, Emcees, Of‍f‍iciants, Corporate Speakers...',
  midLevelServices: [
    {
      name: 'Officiants',
      individualServices: [
        { personForm: 'Wedding Minister', fileName: 'wedding-minister' },
        { personForm: 'Wedding Officiant', fileName: 'wedding-officiant' },
      ],
      viewAllLink: 'speakers',
      noFollowViewAllLink: true,
    },
    {
      name: 'Speakers',
      individualServices: [
        { personForm: 'Auctioneer', fileName: 'auctioneer' },
        { personForm: 'Corporate Speaker', fileName: 'corporate-speaker' },
        { personForm: 'Keynote Speaker', fileName: 'keynote-speaker' },
        { personForm: 'Motivational Speaker', fileName: 'motivational-speaker' },
        { personForm: 'Public Speaker', fileName: 'public-speaker' },
      ],
      viewAllLink: 'speakers',
    },
  ],
};

export const EVENT_SERVICES = {
  pluralForm: 'Event Services',
  hasMidLevelServices: true,
  imageUrl: 'https://media-api.xogrp.com/images/1df24c27-9681-4d35-9b28-b8d02293aa2c',
  description: 'Bartenders, Caterers, Food Trucks...',
  midLevelServices: [
    {
      name: 'Event Planning',
      individualServices: [
        { personForm: 'Event Planner', fileName: 'event-planner' },
        { personForm: 'Wedding Planner', fileName: 'wedding-planner' },
      ],
      viewAllLink: 'event-services',
      noFollowViewAllLink: true,
    },
    {
      name: 'Event Staffing',
      individualServices: [
        { personForm: 'Bartender', fileName: 'bartender' },
        { personForm: 'Caterer', fileName: 'caterer' },
      ],
      viewAllLink: 'event-services',
      noFollowViewAllLink: true,
    },
    {
      name: 'Transportation',
      individualServices: [
        { personForm: 'Bachelorette Party Bus', fileName: 'bachelorette-party-bus' },
        { personForm: 'Bachelor Party Bus', fileName: 'bachelor-party-bus' },
        { personForm: 'Classic Car Rental', fileName: 'classic-car-rental' },
        { personForm: 'Event Limo', fileName: 'event-limo' },
        { personForm: 'Party Bus', fileName: 'party-bus' },
        { personForm: 'Wedding Limo', fileName: 'wedding-limo' },
      ],
      viewAllLink: 'event-services',
      noFollowViewAllLink: true,
    },
  ],
};

export const SERVICE_TILE_DATA = [
  MUSICAL_ENTERTAINMENT,
  PHOTOGRAPHERS,
  VARIETY_ACTS,
  PARTY_RENTALS,
  SPEAKERS_AND_OFFICIANTS,
  EVENT_SERVICES,
];

export const MOBILE_HERO_IMAGE_URL =
  'https://media-api.xogrp.com/images/de8f09ba-ba51-4fc0-b0a9-fcb6528661c3~rs_460.h';
export const DESKTOP_HERO_IMAGE_URL =
  'https://media-api.xogrp.com/images/0777c6c1-559d-4c52-a33f-f129bc63f9ed~rs_876.h';
