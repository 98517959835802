import { TRACK_EVENT } from '@tkww/the-bash-frontend';
import {
  HIGH_LEVEL_SERVICE_TILE_CLICK,
  HIGH_LEVEL_SERVICE_TILE_CLICK_AREA,
  BUTTON_CTA_STYLE,
  CTA_CLICK,
  HYPERLINK_CTA_STYLE,
  ICON_CTA_STYLE,
  ARTICLE_TILE_CLICK,
  EVENT_TILE_CLICK,
} from 'analytics/constants';
import { HOMEPAGE_NAME } from 'constants/pageNames';

export const homepageJumpToCtaClick = (clickTarget) => ({
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    clickArea: 'Search Bar',
    clickTarget,
    ctaStyle: ICON_CTA_STYLE,
  },
});

export const homepageSignupLearnMoreClick = {
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    clickArea: 'Signup Blurb',
    clickTarget: 'Learn More',
    ctaStyle: BUTTON_CTA_STYLE,
  },
};

/**
 * Function to track the high level service tile click
 * @param {string} tileName
 */
export const highLevelServiceTileClick = (tileName) => ({
  type: TRACK_EVENT,
  name: HIGH_LEVEL_SERVICE_TILE_CLICK,
  properties: {
    name: HOMEPAGE_NAME,
    tileName,
  },
});

/**
 * Function to track a service tile click
 * @param {string} clickTarget
 */
export const serviceTileLinkClick = (clickTarget) => ({
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    name: HOMEPAGE_NAME,
    clickArea: HIGH_LEVEL_SERVICE_TILE_CLICK_AREA,
    clickTarget,
  },
});

export const homepageSeeAllEventsClick = {
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    clickArea: 'Events section',
    clickTarget: 'See all events',
    ctaStyle: HYPERLINK_CTA_STYLE,
  },
};

export const homepageSeeAllArticlesClick = {
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    clickArea: 'Articles section',
    clickTarget: 'See all articles',
    ctaStyle: HYPERLINK_CTA_STYLE,
  },
};

export const homepageEventTileClick = ({ eventType, eventPath }) => ({
  type: TRACK_EVENT,
  name: EVENT_TILE_CLICK,
  properties: {
    eventType,
    eventPath,
  },
});

export const homepageArticleTileClick = ({ articleName, articleSlug }) => ({
  type: TRACK_EVENT,
  name: ARTICLE_TILE_CLICK,
  properties: {
    articleName,
    articleSlug,
  },
});
